<template>
    <div class="form-section mt-4 ms-4">
      <!-- Container for title and button -->
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <h5 style="font-weight: bolder; color: #555555">F&B Extras Revenues</h5>
        </div>
        <div>
          <!-- Display the total sum of all total_revenue, with € symbol only if total is greater than 0 -->
          <h5 style="font-weight: bolder; color: #555555">
            ΣΥΝΟΛΟ: {{ formattedBudgetTotal }}
            <span v-if="budgetTotal"> €</span>
          </h5>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-start ">
      <!-- Title -->
    </div>

    <!-- Board Type and Supplement Section -->
    <div class="mt-2 row">
      <div class="col-md-3 card shadow-custom p-3 mb-3 bg-white rounded position-relative">
        <!-- Non-Editable View for Board Types -->
        <div v-if="!isEditingHotelPeriod">
          <div class="row" style="color:#A6A6A6; font-weight:bolder">
            <div class="col-md-12">
              <p class="label-with-circle"><strong>Χρονική περίοδος λειτουργίας του ξενοδοχείου</strong><hr/></p>
              <p>Από: {{ dateFromFormatted }}</p>
              <p>Έως: {{ dateToFormatted }}</p>

            </div>
          </div>
          <!-- Edit Button for Period Date -->
          <div class="action-buttons">
            <button class="btn border-0 p-1 custom-action-buttons" @click="editHotelPeriod">
              <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
            </button>
          </div>
        </div>

        <!-- Editable View for Period Date -->
        <div v-else>
          <div class="row" style="color:#4A4A4A; font-weight:bolder">
            <div class="col-md-12">
              <p class="label-with-circle2"><strong>Χρονική περίοδος λειτουργίας του ξενοδοχείου</strong><hr/></p>
              <!-- Date Input Fields -->
              <div class="mb-3 d-flex align-items-center">
        <label for="dateFromInput" class="form-label me-2 mb-0">Από:</label>
        <input 
          type="date" 
          id="dateFromInput" 
          class="form-control" 
          style="max-width:60%"
          v-model="editableDateFrom"
          @change="updateDateFrom"
        />
      </div>

      <div class="mb-3 d-flex align-items-center">
        <label for="dateToInput" class="form-label me-2 mb-0">Έως:</label>
        <input 
          type="date" 
          id="dateToInput" 
          class="form-control" 
          :min="editableDateFrom"
          style="max-width:60%"
          v-model="editableDateTo"
        />
      </div>
            </div>
          </div>
               
    <div class="row form-actions mt-4">
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelHotelPeriod">
        ΑΚΥΡΩΣΗ
      </button>
          </div>
        </div>
   
    <!-- Form Actions -->
    <div class="action-buttons">
      <button class="btn border-0 p-1 save " @click="saveHotelPeriod">
        <img src="../assets/images/save.png" alt="Save" width="30">
      </button>
    </div>
        </div>
      </div>

    </div>

      <!-- Display list of revenue categories -->
      <div v-if="dateFromFormatted && dateToFormatted" class="mt-4 row">
        <div
          v-for="(revenue, index) in items"
          :key="revenue.id"
          class="col-md-11 card shadow-custom mb-3 bg-white rounded position-relative"
        >
          <!-- Non-Editable View -->
          <div v-if="!revenue.isEditing">
            <div class="row align-items-center" style="color:#A6A6A6; font-weight:bolder">
    <div class="col-md-8">
      <p class="label-with-circle">
        <strong>{{ revenue.restaurant_kind }}</strong>
      </p>
    </div>
    <div class="col-md-4 text-end">
      <p>
        <strong>Σύνολο Εσόδου:</strong> {{ formatNumber(revenue.total_fnb_revenues) }} €
      </p>
    </div><hr/><br>

    <div class="col-md-4">
        <p><strong>Monthly Ratio Guest/Room:</strong> {{ revenue.monthly_ratio }}</p>
    </div>

  <!-- Table Section -->
  <div class="table-responsive mt-4">
    <table class="table table-bordered text-center">
      <thead class="table-dark">
        <tr>
          <th></th>
          <th v-for="revenueMonth in revenue.revenues" :key="revenueMonth.id">
            {{ getMonthName(revenueMonth.date_from) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><strong>Available Room Nights</strong></td>
          <td v-for="revenueMonth in revenue.revenues" :key="'room-' + revenueMonth.id">
            {{ revenueMonth.available_room_nights_per_month }}
          </td>
        </tr>
        <tr>
          <td><strong>Available Pax Nights</strong></td>
          <td v-for="revenueMonth in revenue.revenues" :key="'pax-' + revenueMonth.id">
            {{ revenueMonth.available_pax_nights_per_month }}
          </td>
        </tr>
        <tr>
          <td><strong>Monthly Occupancy (%)</strong></td>
          <td v-for="revenueMonth in revenue.revenues" :key="'occupancy-' + revenueMonth.id">
            {{ revenueMonth.monthly_occupancy }}
          </td>
        </tr>
        <tr>
          <td><strong>Room Nights Sold</strong></td>
          <td v-for="revenueMonth in revenue.revenues" :key="'room-sold-' + revenueMonth.id">
            {{ revenueMonth.room_nights_sold_per_month }}
          </td>
        </tr>
        <tr>
          <td><strong>Pax Nights Sold</strong></td>
          <td v-for="revenueMonth in revenue.revenues" :key="'pax-sold-' + revenueMonth.id">
            {{ revenueMonth.pax_nights_sold_per_month }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-md-12 align-items-center justify-content-center mt-4">
      <p>
        <strong>Μonthly Supplement Pick ups</strong>
      </p>
    </div>

   <!-- Table Section -->
   <div class="table-responsive ">
    <table class="table table-bordered text-center">
      <thead class="table-dark">
        <tr>
          <th></th>
          <th v-for="revenueMonth in revenue.revenues" :key="revenueMonth.id">
            {{ getMonthName(revenueMonth.date_from) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><strong>BB</strong></td>
          <td v-for="revenueMonth in revenue.revenues" :key="'bb-' + revenueMonth.id">
            {{ revenueMonth.monthly_supplement_pick_up_bb }}
          </td>
        </tr>
        <tr>
          <td><strong>HB</strong></td>
          <td v-for="revenueMonth in revenue.revenues" :key="'hb-' + revenueMonth.id">
            {{ revenueMonth.monthly_supplement_pick_up_hb }}
          </td>
        </tr>
        <tr>
          <td><strong>FB</strong></td>
          <td v-for="revenueMonth in revenue.revenues" :key="'fb-' + revenueMonth.id">
            {{ revenueMonth.monthly_supplement_pick_up_fb }}
          </td>
        </tr>
        <tr>
          <td><strong>AI</strong></td>
          <td v-for="revenueMonth in revenue.revenues" :key="'ai-' + revenueMonth.id">
            {{ revenueMonth.monthly_supplement_pick_up_ai }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-md-12 align-items-center justify-content-center mt-4">
      <p>
        <strong>F&B Extra Revenue/Guest</strong>
      </p>
    </div>

   <!-- Table Section -->
   <div class="table-responsive">
    <table class="table table-bordered text-center">
      <thead class="table-dark">
        <tr>
          <th></th>
          <th v-for="revenueMonth in revenue.revenues" :key="revenueMonth.id">
            {{ getMonthName(revenueMonth.date_from) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><strong>BB</strong></td>
          <td v-for="revenueMonth in revenue.revenues" :key="'bb-' + revenueMonth.id">
            {{ revenueMonth.extra_revenue_per_guest_bb }}
          </td>
        </tr>
        <tr>
          <td><strong>HB</strong></td>
          <td v-for="revenueMonth in revenue.revenues" :key="'hb-' + revenueMonth.id">
            {{ revenueMonth.extra_revenue_per_guest_hb }}
          </td>
        </tr>
        <tr>
          <td><strong>FB</strong></td>
          <td v-for="revenueMonth in revenue.revenues" :key="'fb-' + revenueMonth.id">
            {{ revenueMonth.extra_revenue_per_guest_fb }}
          </td>
        </tr>
        <tr>
          <td><strong>AI</strong></td>
          <td v-for="revenueMonth in revenue.revenues" :key="'ai-' + revenueMonth.id">
            {{ revenueMonth.extra_revenue_per_guest_ai }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>



            <!-- Edit Button -->
            <div class="action-buttons">
              <button class="btn border-0 p-1 edit" @click="editItem(revenue)">
                <img src="../assets/images/edit-budget.png" alt="Edit" width="30" />
              </button>
            </div>
          </div>
  
          <!-- Editable View -->
          <div v-else>
    <div class="row align-items-center" style="color:#A6A6A6; font-weight:bolder">
      <div class="col-md-8">
        <p class="label-with-circle2">
        <strong style="color:black">{{ revenue.restaurant_kind }}</strong>
      </p>
      </div>
     
    </div>

    <div class="row align-items-center">
  <div class="col-md-6">
    <div class="d-flex align-items-center justify-content-between">
      <strong>Monthly Ratio Guest/Room:</strong>
      <input
        type="number"
        v-model="revenue.monthly_ratio"
        class="form-control ms-2"
        placeholder="Ratio"
        style="max-width: 40%;"
      />
    </div>
  </div>
</div>


     <div class="col-md-12 align-items-center justify-content-center mt-4">
      <p>
        <strong>Μonthly Supplement Pick Ups</strong>
      </p>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered text-center">
        <thead class="table-dark">
          <tr>
            <th></th>
            <th v-for="month in generatedMonths(revenue)" :key="month">
              {{ month }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="type in ['BB', 'HB', 'FB', 'AI']" :key="type">
            <td><strong>{{ type }}</strong></td>
            <td
  v-for="(month, index) in generatedMonths(revenue)"
  :key="type + '-' + index"
>
  <input
    type="number"
    :value="getSupplementData(revenue.revenues, month, type)" 
    class="form-control"
    @input="setSupplementData($event.target.value, month, type)" 
  />
</td>

          </tr>
        </tbody>
      </table>
    </div>

    <div class="col-md-12 align-items-center justify-content-center mt-4">
      <p>
        <strong>F&B Extra Revenue/Guest</strong>
      </p>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered text-center">
        <thead class="table-dark">
          <tr>
            <th></th>
            <th v-for="month in generatedMonths(revenue)" :key="month">
              {{ month }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="type in ['BB', 'HB', 'FB', 'AI']" :key="type">
            <td><strong>{{ type }}</strong></td>
            <td
  v-for="(month, index) in generatedMonths(revenue)"
  :key="type + '-' + index"
>
  <input
    type="number"
    :value="getExtraRevenueData(revenue.revenues, month, type)" 
    class="form-control"
    @input="setExtraRevenueData($event.target.value, month, type)" 
  />
</td>

          </tr>
        </tbody>
      </table>
      </div> 
      <div class="row form-actions mt-4">
        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary justify-content-end align-items-end"
                  @click="cancelEdit(revenue)">
                  ΑΚΥΡΩΣΗ
          </button>
        </div>
      </div>
       <!-- Form Actions -->
      <div class="action-buttons">
        <button class="btn border-0 p-1 save" @click="confirmSaveItem(index)">
          <img src="../assets/images/save.png" alt="Save" width="30" />
        </button>
      </div>
    </div>
    </div> 
  </div>
</div>
  </template>
  
  <script>
  import { useHead } from '@vueuse/head';
  import { getFnbExtrasRevenues, updateFnbExtrasRevenues } from '@/services/FnbExtrasRevenue';
  import { useToast } from 'vue-toastification';
  import { fetchBudgetInfo, fetchUsers, changeUser, fetchUserInfo } from '../services/user';
  import { getBudget, updateHotelPeriod } from "@/services/budget";
  import dayjs from 'dayjs';

  
  export default {
  name: 'FnbExtrasRevenue',
  data() {
    return {
      showForm: false,
      form: {
        title: '',
        description: '',
      },
      revenue: {
        date_from: null,
        date_to: null,
        revenues: [],
      },
      items: [],
      currentEditRevenue: null,
      budgetTotal: 0,
      budgetYear: null,  
      dateFrom: null,
      dateΤο: null,
      editableDateFrom: null,
      editableDateΤο: null,
      dateFromFormatted: null,
      dateToFormatted: null,
      months: [],
      isEditingHotelPeriod: false,

    };
  },

  computed: {
    // Format the total revenue with European formatting
    formattedBudgetTotal() {
      return new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(this.budgetTotal);
    },
  },

  watch: {
    'revenue.date_from'(newStartDate) {
      this.updateMonths();
    },
    'revenue.date_to'(newEndDate) {
      this.updateMonths();
    },
  },

  methods: {
    // Format number with European decimal places
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },

    // Generate month names based on date_from and date_to
    generatedMonths(revenue) {
      return this.calculateMonthNames(revenue.date_from, revenue.date_to);
    },

    // Update months list when date range changes
    updateMonths() {
      this.months = this.calculateMonthNames(this.revenue.date_from, this.revenue.date_to);
      console.log('Updated months:', this.months);  // For debugging
    },

    // Calculate the month names between two dates
    calculateMonthNames(startDate, endDate) {
      const months = [];
      if (startDate && endDate) {
        let start = new Date(startDate);
        const end = new Date(endDate);

        // Loop through each month and push its name to the array
        while (start <= end) {
          months.push(new Intl.DateTimeFormat("el-GR", { month: "long" }).format(start));
          start.setMonth(start.getMonth() + 1);
        }

        // Ensure the last month is included even if not in the initial range
        const lastMonth = new Date(endDate);
        if (months[months.length - 1] !== new Intl.DateTimeFormat("el-GR", { month: "long" }).format(lastMonth)) {
          months.push(new Intl.DateTimeFormat("el-GR", { month: "long" }).format(lastMonth));
        }

        console.log("Months:", months);
      }
      return months;
    },

    editHotelPeriod() {
      this.isEditingHotelPeriod = true;
      this.editableDateFrom = this.dateFrom;
      this.editableDateTo = this.dateTo;
    },
    saveHotelPeriod() {
  // Save edited values
  this.dateFrom = this.editableDateFrom;
  this.dateTo = this.editableDateTo;

  // Call the API to update the hotel period
  updateHotelPeriod(this.$route.params.budget_id, { date_from: this.dateFrom, date_to: this.dateTo })
    .then(() => {
      // Notify success
      useToast().success('Η χρονική περίοδος λειτουργίας του ξενοδοχείου καταχωρήθηκε!');

      // Reload the page after a delay of 1 second
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch((error) => {
      // Handle the error, optionally notify the user
      useToast().error('Failed to save board type!');
      console.error('Error updating hotel period:', error);
    });

  // Exit editing mode
  this.isEditingHotelPeriod = false;
},


cancelHotelPeriod() {
  this.isEditingHotelPeriod = false;
  this.editableDateFrom = this.dateFrom; // Reset to original value
  this.editableDateTo = this.dateTo; // Reset to original value
},


    // Ensure data is initialized for all months, even the last one (e.g., July)
    initializeMonthData() {
      this.months.forEach((month) => {
        if (!this.revenue.revenues[month]) {
          this.revenue.revenues[month] = {
            extra_revenue_per_guest_ai: null,
            extra_revenue_per_guest_bb: null,
            extra_revenue_per_guest_fb: null,
            extra_revenue_per_guest_hb: null,
            monthly_supplement_pick_up_ai: null,
            monthly_supplement_pick_up_bb: null,
            monthly_supplement_pick_up_fb: null,
            monthly_supplement_pick_up_hb: null,
          };
        }
      });
    },

    // Get the supplement data for a specific month and type
    getSupplementData(revenues, month, type) {
      if (!revenues || revenues.length === 0) return null;
      const revenueData = revenues.find((rev) => this.getMonthName(rev.date_from) === month);
      return revenueData ? revenueData[`monthly_supplement_pick_up_${type.toLowerCase()}`] : null;
    },

    // Get extra revenue data for a specific month and type
    getExtraRevenueData(revenues, month, type) {
      if (!revenues || revenues.length === 0) return null;
      const revenueData = revenues.find((rev) => this.getMonthName(rev.date_from) === month);
      return revenueData ? revenueData[`extra_revenue_per_guest_${type.toLowerCase()}`] : null;
    },

    // Set supplement data for a specific month and type
    setSupplementData(value, month, type) {
      const revenueData = this.revenue.revenues.find(
        (rev) => this.getMonthName(rev.date_from) === month
      );
      if (revenueData) {
        revenueData[`monthly_supplement_pick_up_${type.toLowerCase()}`] = Number(value);
      }
      this.handleMonthDataChange(month, type, value);
    },

    // Set extra revenue data for a specific month and type
    setExtraRevenueData(value, month, type) {
      const revenueData = this.revenue.revenues.find(
        (rev) => this.getMonthName(rev.date_from) === month
      );
      if (revenueData) {
        revenueData[`extra_revenue_per_guest_${type.toLowerCase()}`] = Number(value);
      }
      this.handleMonthDataChange(month, type, value);
    },

    // Get the month name for a specific date
    getMonthName(date) {
      return new Intl.DateTimeFormat("el-GR", { month: "long" }).format(new Date(date));
    },

    // Handle data change for a specific month
    handleMonthDataChange(month, type, value) {
      if (!this.revenue.revenues[month]) {
        this.revenue.revenues[month] = {};
      }
      this.revenue.revenues[month][type] = value;
    },

    // Fetch the list of revenue items from the API
    async fetchItems() {
      try {
        const response = await getFnbExtrasRevenues(this.$route.params.budget_id);
        this.items = response.data.map((item) => ({ ...item, isEditing: false }));
        if (this.items.length > 0) {
          this.revenue = this.items[0]; // Use the first item
        }
        this.initializeMonthData(); // Ensure all months are initialized
      } catch (error) {
        console.error('Error fetching fnb revenues:', error);
      }
    },

    // Fetch the total budget revenue
    async fetchBudgetInfoData() {
  try {
    const response = await fetchBudgetInfo(this.$route.params.budget_id);

    if (response && response.data && response.data.data) {
      this.budgetTotal = response.data.data.total_fnb_revenues || 0;

      // Convert raw dates using dayjs to avoid time zone adjustments
      this.dateFrom = response.data.data.date_from
        ? dayjs(response.data.data.date_from).format('YYYY-MM-DD')
        : null;
      this.dateTo = response.data.data.date_to
        ? dayjs(response.data.data.date_to).format('YYYY-MM-DD')
        : null;

      // Assign formatted dates for display purposes
      this.dateFromFormatted = response.data.data.date_from_formatted || null;
      this.dateToFormatted = response.data.data.date_to_formatted || null;

      // Extract the year
      this.budgetYear = dayjs(response.data.data.year).year();

    }
  } catch (error) {
    console.error('Failed to fetch budget info:', error);
  }
},



updateDateFrom() {
    // This ensures "To" input only allows dates after the selected "From" date
    if (this.editableDateTo && this.editableDateFrom > this.editableDateTo) {
      this.editableDateTo = this.editableDateFrom;
    }
  },

  disableInvalidDates(date) {
    const year = dayjs(date).year();
    return year === this.budgetYear;
  },
    // Start editing a specific revenue item
    editItem(revenue) {
      this.currentEditRevenue = revenue;
      revenue.isEditing = true;
    },

    // Cancel editing
    cancelEdit(revenue) {
      revenue.isEditing = false;
      this.currentEditRevenue = null;
    },

    // Save the updated item via API
    async confirmSaveItem(index) {
      try {
        const revenue = this.items[index];
        const payload = {
          date_from: revenue.date_from,
          date_to: revenue.date_to,
          monthly_ratio: revenue.monthly_ratio,
          monthly_supplement_pick_up_bb: this.formatMonthlyData(revenue.revenues, 'monthly_supplement_pick_up_bb'),
          monthly_supplement_pick_up_hb: this.formatMonthlyData(revenue.revenues, 'monthly_supplement_pick_up_hb'),
          monthly_supplement_pick_up_fb: this.formatMonthlyData(revenue.revenues, 'monthly_supplement_pick_up_fb'),
          monthly_supplement_pick_up_ai: this.formatMonthlyData(revenue.revenues, 'monthly_supplement_pick_up_ai'),
          extra_revenue_per_guest_bb: this.formatMonthlyData(revenue.revenues, 'extra_revenue_per_guest_bb'),
          extra_revenue_per_guest_hb: this.formatMonthlyData(revenue.revenues, 'extra_revenue_per_guest_hb'),
          extra_revenue_per_guest_fb: this.formatMonthlyData(revenue.revenues, 'extra_revenue_per_guest_fb'),
          extra_revenue_per_guest_ai: this.formatMonthlyData(revenue.revenues, 'extra_revenue_per_guest_ai'),
        };

        // Send the payload to the backend
        const response = await updateFnbExtrasRevenues(revenue.id, payload);

        if (response.success) {
          useToast().success('Changes saved successfully');
        } else {
          useToast().error('Failed to save changes');
        }

        // End editing mode and refresh data
        revenue.isEditing = false;
        this.fetchItems();
        this.fetchBudgetInfoData();
      } catch (error) {
        console.error('Error saving revenue:', error);
      }
    },

    // Format the monthly data
    formatMonthlyData(revenues, field) {
      const formattedData = {};
      revenues.forEach((revenue) => {
        const month = new Date(revenue.date_from).getMonth() + 1; // Get the month (1-indexed)
        formattedData[month] = revenue[field] || null;
      });
      return formattedData;
    },
  },

  mounted() {
    this.fetchItems();
    this.fetchBudgetInfoData();
    useHead({
      title: 'F&B Extras Revenues',
      meta: [
        { name: 'description', content: 'Manage F&B extras revenues for outlets' },
      ],
    });
  },
};
</script>


  <style scoped>
  .form-container {
   background: #f8f9fa;
   padding: 20px;
   border-radius: 8px;
   border: 1px solid #ddd;
 }
 
 .card {
   padding: 15px;
   background: #fff;
   border-radius: 8px;
   border: 1px solid #ddd;
   position: relative;
   margin-left:35px;
 }
 
 .action-buttons {
   position: absolute;
   top: 100px;
   right: 10px;
 }
 
 
 
 hr {
   margin: 10px 0;
 }
 
 .modal-backdrop {
   opacity: 0.5 !important;
 }
 /* Adding a circle bullet point next to each label */
 .label-with-circle::before {
   content: '';
   display: inline-block;
   width: 10px;
   height: 10px;
   background-color: #A6A6A6;
   border-radius: 50%;
   margin-right: 10px;
   vertical-align: middle;
 }
 
 .label-with-circle2::before {
   content: '';
   display: inline-block;
   width: 10px;
   height: 10px;
   background-color: var(--primary-color);
   border-radius: 50%;
   margin-right: 10px;
   vertical-align: middle;
   color:black;
 }
 /* Aligning middle elements like Basic Board and Supplement */
 .align-middle {
   margin-top: 30px; /* Adjust this value based on the spacing you want */
 }
 
 /* Custom shadow for outlet-card */
 .shadow-custom {
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
   transition: box-shadow 0.3s ease-in-out;
 }
 
 .shadow-custom:hover {
   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
 }
 
 
 
 .edit{
   position: absolute;
 
   top: 25%; /* Adjust the vertical position to be slightly above middle */
   right: -30px; /* Half the button outside the outlet-card */
   transform: translateY(-150%); /* Center vertically relative to its position */
 
 }
 
 .save {
   position: absolute;
   top: 30%; /* Adjust as needed to lower the button */
   right: -18px; /* Adjust to move outside the card */
   transform: translateY(-150%); /* Further adjust for desired position */
 }
 
 .delete {
   position: absolute;
   top: 50%; /* Adjust to position higher or lower */
   right: -18px; /* Same as above */
   transform: translateY(30%); /* Further adjust for desired position */
 
 }

 .custom-action-buttons {
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-172%);
  background: #67B662;
  width: 40px;
  height: 40px;
}

.custom-action-buttons:hover {
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-172%);
  background: #67B662;
  width: 40px;
  height: 40px;
}
 </style>
 
