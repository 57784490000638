<template>
  <footer>
    <span style="color:#FFFFFF">
      &copy; {{ currentYear }} LABC | Powered by 
      <a href="https://citrine.gr/" target="_blank" style="color:#FFFFFF;">
        <i>Citrine</i>
      </a>. All rights reserved.
    </span>
  </footer>
</template>

<script>
export default {
  name: 'MainFooter',
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
};
</script>

<style scoped>
footer {
  background-color: #E1961F;
  color: #FFFFFF;
  padding: 5px;
  text-align: center;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  font-size: 13px;
  position: fixed;
}
a:hover {
  color: #2776A0;
}
</style>
