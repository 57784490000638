import apiClient from './api';

async function createBudget(hotelId, data) {
  try {
    const response = await apiClient.post('/create-budget/' + hotelId, data);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return { success: false, message: "No response received", details: error.request };
    } else {
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function getAllBudgets(hotelId) {
  try {
    const response = await apiClient.get('/all-budgets/' + hotelId);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return { success: false, message: "No response received", details: error.request };
    } else {
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function getBudget(budgetId) {
  try {
    const response = await apiClient.get('/get-current-budget/' + budgetId);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return { success: false, message: "No response received", details: error.request };
    } else {
      return { success: false, message: "Request setup error", details: error.message };
    }
  }
}

async function updateBudget(budgetId, data) {
    try {
      const response = await apiClient.post('/update-budget/' + budgetId, data);
      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return { success: false, message: "No response received", details: error.request };
      } else {
        return { success: false, message: "Request setup error", details: error.message };
      }
    }
  }

  async function updateSupplement(budgetId, data) {
    try {
      const response = await apiClient.post('/update-supplements/' + budgetId, data);
      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return { success: false, message: "No response received", details: error.request };
      } else {
        return { success: false, message: "Request setup error", details: error.message };
      }
    }
  }

  async function updateSpa(budgetId, data) {
    try {
      const response = await apiClient.post('/update-spa/' + budgetId, data);
      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return { success: false, message: "No response received", details: error.request };
      } else {
        return { success: false, message: "Request setup error", details: error.message };
      }
    }
  }

  async function updateHotelPeriod(budgetId, data) {
    try {
      const response = await apiClient.post('/update-budget-period/' + budgetId, data);
      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return { success: false, message: "No response received", details: error.request };
      } else {
        return { success: false, message: "Request setup error", details: error.message };
      }
    }
  }


  async function updatePayrollRelatedCosts(budgetId, data) {
    try {
      const response = await apiClient.post('/update-payroll-related-costs/' + budgetId, data);
      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return { success: false, message: "No response received", details: error.request };
      } else {
        return { success: false, message: "Request setup error", details: error.message };
      }
    }
  }

  async function updateBudgetForm(budgetId, data) {
    try {
      const response = await apiClient.post('/update-budget-form/' + budgetId, data);
      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return { success: false, message: "No response received", details: error.request };
      } else {
        return { success: false, message: "Request setup error", details: error.message };
      }
    }
  }
  
  async function deleteBudget(budgetId) {
    try {
      const response = await apiClient.post('/delete-budget/' + budgetId);
      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return { success: false, message: "No response received", details: error.request };
      } else {
        return { success: false, message: "Request setup error", details: error.message };
      }
    }
  }

  async function markBudgetAsFinal(budgetId) {
    try {
      const response = await apiClient.post('/mark-budget-as-final/' + budgetId, {});
      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else if (error.request) {
        return { success: false, message: "No response received", details: error.request };
      } else {
        return { success: false, message: "Request setup error", details: error.message };
      }
    }
  }

export { getBudget, updateBudget, getAllBudgets, createBudget, updateBudgetForm, markBudgetAsFinal, deleteBudget, updatePayrollRelatedCosts, updateHotelPeriod, updateSupplement, updateSpa };
