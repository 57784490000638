<template>
  <div class="form-section mt-4 ms-4">

      <!-- Container for Season Dates and Add Button -->
      <div class="mt-2 d-flex align-items-center justify-content-between">
        <h5 style="font-weight: bolder; color: #555555;">SEASON DATES</h5>
      <button @click="addSeason" class="btn btn-primary fw-bold">ΠΡΟΣΘΗΚΗ +</button>
    </div>

    <!-- Seasons List -->
    <div class="mt-3 row" style="width:100%">
      <div
        v-for="(season, index) in seasons"
        :key="season.id"
        class="col-md-3 card shadow-custom mb-3 ms-5 bg-white rounded position-relative"
      >
        <!-- Non-Editable View for Seasons -->
        <div v-if="!season.isEditing">
          <div class="row" style="color:#A6A6A6; font-weight:bolder; width: 100%;">
            <div class="col-md-12">
              <p class="label-with-circle"><strong>Season {{ index + 1 }}</strong><hr/></p>
              <p><strong>From:</strong> {{ season.season_date_from_formatted }}</p>
              <p><strong>To:</strong> {{ season.season_date_to_formatted }}</p>
              <p><strong>Season Type:</strong> {{ season.season_type }}</p>
            </div>
          </div>
          <div class="action-buttons" v-if="!season.isEditing">
            <button class="btn border-0 p-1 edit-button-seasons" @click="editSeason(index)">
              <img src="../assets/images/edit-budget.png" alt="Edit" />
            </button>
          </div>
        </div>

        <!-- Editable View for Seasons -->
        <div v-else>
  <div class="row" style="color:#4A4A4A; font-weight:bolder;">
    <div class="col-md-12">
      <p class="label-with-circle2"><strong>Season {{ index + 1 }}</strong><hr/></p>
      
      <!-- Date From Row -->
      <div class="row align-items-center mb-3">
        <div class="col-md-3">
          <label><strong>Date From</strong></label>
        </div>
        <div class="col-md-9">
          <input type="date" v-model="season.season_date_from" class="form-control" />
        </div>
      </div>

      <!-- Date To Row -->
      <div class="row align-items-center mb-3">
        <div class="col-md-3">
          <label><strong>Date To</strong></label>
        </div>
        <div class="col-md-9">
          <input type="date" v-model="season.season_date_to" class="form-control" />
        </div>
      </div>

      <!-- Season Type Row -->
      <div class="row align-items-center mb-3">
        <div class="col-md-3">
          <label><strong>Season Type</strong></label>
        </div>
        <div class="col-md-9">
          <select v-model="season.season_type" class="form-control">
            <option value="Middle">Mid</option>
            <option value="High">High</option>
            <option value="Low">Low</option>
          </select>
        </div>
      </div>

      <!-- Cancel Button -->
      <div class="d-flex justify-content-end mt-3">
        <button class="btn btn-secondary" @click="cancelEditSeason(index)">
          ΑΚΥΡΩΣΗ
        </button>
      </div>
    </div>
  </div>

  <!-- Action Buttons -->
  <div class="action-buttons mt-4 d-flex flex-column" v-if="season.isEditing">
   
    <button class="btn border-0 p-1 delete-button-seasons" @click="openDeleteSeasonModal(index)">
      <img src="../assets/images/bin.png" alt="Delete" />
    </button>
    <button class="btn border-0 p-1 save save-seasons " @click="saveAllSeasons">
        <img src="../assets/images/save.png" alt="Save" width="30">
      </button>
  </div>
</div>

      </div>
     
    </div>
     <!-- Container for title and button -->
     <div class="d-flex align-items-center justify-content-start ">
      <!-- Title -->
      <h5 style="font-weight: bolder; color: #555555">BOARD TYPES</h5>
    </div>


    <!-- Board Type and Supplement Section -->
    <div class="mt-2 row">
      <div class="col-md-3 card shadow-custom p-3 ms-5 bg-white rounded position-relative">
        <!-- Non-Editable View for Board Types -->
        <div v-if="!isEditingBoardTypes">
          <div class="row" style="color:#A6A6A6; font-weight:bolder">
            <div class="col-md-8">
              <p class="label-with-circle"><strong>Basic Board</strong><hr/></p>
              <p>{{ boardType }}</p>
            </div>
          </div>
          <!-- Edit Button for Board Types -->
          <div class="action-buttons">
            <button class="btn border-0 p-1 custom-action-buttons" @click="editBoardTypes">
              <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
            </button>
          </div>
        </div>

        <!-- Editable View for Board Types -->
        <div v-else>
          <div class="row" style="color:#4A4A4A; font-weight:bolder">
            <div class="col-md-8">
              <p class="label-with-circle2"><strong>Basic Board</strong><hr/></p>
              <select v-model="editableBoardType" class="form-control">
                <option v-for="option in boardTypesOptions" :key="option.id" :value="option.id">
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
               
    <div class="row form-actions mt-4">
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelEditBoardTypes">
        ΑΚΥΡΩΣΗ
      </button>
          </div>
        </div>
   
    <!-- Form Actions -->
    <div class="action-buttons">
      <button class="btn border-0 p-1 save " @click="saveBoardTypes">
        <img src="../assets/images/save.png" alt="Save" width="30">
      </button>
    </div>
        </div>
      </div>

      <div class="col-md-3 card shadow-custom p-3 ms-5 bg-white rounded position-relative">
    <!-- Non-Editable View for Supplements -->
    <div v-if="!isEditingSupplements">
      <div class="row" style="color:#A6A6A6; font-weight:bolder">
        <div class="col-md-8">
          <p class="label-with-circle"><strong>Supplement</strong><hr/></p>
          <!-- Show selected supplements as a comma-separated list -->
          <p v-if="supplements && supplements.length">{{ supplements.join(', ') }}</p>
<p v-else></p>
        </div>
      </div>
      <!-- Edit Button -->
      <div class="action-buttons">
        <button class="btn border-0 p-1 custom-action-buttons" @click="editSupplements">
          <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
        </button>
      </div>
    </div>

    <!-- Editable View for Supplements -->
    <div v-else>
      <div class="row" style="color:#4A4A4A; font-weight:bolder">
        <div class="col-md-8">
          <p class="label-with-circle2"><strong>Supplement</strong><hr/></p>
          <!-- Vue3-Select2 Dropdown -->
          <Select2
            v-model="editableSupplements"
            :options="supplementsOptions"
            :settings="select2Settings"
            :multiple="true"
            :placeholder="'Select supplements'"
            class="form-control"
          />
        </div>
      </div>

          
    <div class="row form-actions mt-4">
          <div class="d-flex justify-content-end">
            <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelEditSupplements">
        ΑΚΥΡΩΣΗ
      </button>
          </div>
        </div>
   
    <!-- Form Actions -->
    <div class="action-buttons">
      <button class="btn border-0 p-1 save " @click="saveSupplements">
        <img src="../assets/images/save.png" alt="Save" width="30">
      </button>
    </div>
        </div>
      </div>
    </div>

   <!--  <div class="mt-2 d-flex align-items-center justify-content-between">
  <h5 style="font-weight: bolder; color: #555555;">SPA</h5>
</div>

<div class="mt-2 row">
  <div class="col-md-3 card shadow-custom p-3 ms-5 bg-white rounded position-relative">
    <div v-if="!isEditingSpa">
      <div class="row" style="color:#A6A6A6; font-weight:bolder">
        <div class="col-md-8">
          <p class="label-with-circle"><strong>Ύπαρξη SPA</strong><hr/></p>
          <p>{{ hasSpa }}<span v-if="this.editableSpa == 1">, {{ this.spa_operation.title }}</span></p>
        </div>
      </div>
      <div class="action-buttons">
        <button class="btn border-0 p-1 custom-action-buttons" @click="editSpa">
          <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
        </button>
      </div>
    </div>

    <div v-else>
      <div class="row" style="color:#4A4A4A; font-weight:bolder">
        <div class="col-md-8">
          <p class="label-with-circle2"><strong>Ύπαρξη SPA</strong><hr/></p>
          <select v-model="editableSpa" class="form-control">
            <option value="1">NAI</option>
            <option value="0">ΟΧΙ</option>
          </select>
        </div>
        <div class="col-md-8" >
          <p class="label-with-circle2"><strong>Λειτουργία SPA</strong><hr/></p>
          <select v-model="editableSpaOperation" class="form-control">
            <option value="1">INHOUSE ΛΕΙΤΟΥΡΓΙΑ</option>
            <option value="2">ΑΝΑΘΕΣΗ ΣΕ ΕΞΩΤΕΡΙΚΟ ΣΥΝΕΡΓΑΤΗ</option>
          </select>
        </div>
      </div>

      <div class="row form-actions mt-4">
        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelEditSpa">
            ΑΚΥΡΩΣΗ
          </button>
        </div>
      </div>

      <div class="action-buttons">
        <button class="btn border-0 p-1 save" @click="saveSpa">
          <img src="../assets/images/save.png" alt="Save" width="30">
        </button>
      </div>
    </div>
  </div>

</div>  -->


    <!-- Container for title and button -->
    <div class="mt-4 d-flex align-items-between justify-content-between">
      <!-- Title -->
      <h5 style="font-weight: bolder; color: #555555">NEW ROOM TYPE</h5>

      <!-- Add Button -->
      <button v-if="!showForm" class="btn btn-primary fw-bold" style="margin-left:10px;" @click="showAddForm">ΠΡΟΣΘΗΚΗ +</button>
    </div>

    <!-- Form Container -->
    <div v-if="showForm" class="form-container">
      <!-- Form Fields -->
      <div class="row" style="color:#4A4A4A; font-weight:bolder">
        <!-- Left Column -->
        <div class="col-md-4">
          <p class="label-with-circle2"><strong>ROOM TYPE</strong><hr/></p>
          <input
            type="text"
            v-model="form.title"
            class="form-control"
            maxlength="255"
            required
          />
        </div>

        <!-- Middle Column -->
        <div class="col-md-4 form-group">
          <p class="label-with-circle2"><strong>ΜΕΓΕΘΟΣ ΔΩΜΑΤΙΟΥ</strong><hr/></p>
          <input
            type="number"
            v-model.number="form.size"
            class="form-control"
            placeholder="π.χ. 25"
          /><br/>

          <p class="label-with-circle2"><strong>ALLOTMENT</strong><hr/></p>
          <input
            type="number"
            v-model.number="form.allotment"
            class="form-control"
            placeholder="π.χ. 3"
          />
        </div>

        <!-- Right Column -->
        <div class="col-md-4 form-group">
          <p class="label-with-circle2"><strong>CAPACITY</strong><hr/></p>
          <div>
            <label><strong>Ελάχιστη:</strong></label>
            <input
              type="number"
              v-model.number="form.min_capacity"
              class="form-control me-2"
              placeholder="Ελάχιστη"
            />
            <label><strong>Μέγιστη:</strong></label>
            <input
              type="number"
              v-model.number="form.max_capacity"
              class="form-control"
              placeholder="Μέγιστη"
            />
          </div>
        </div>
      </div>

      <!-- Form Actions -->
      <div class="row form-actions mt-4">
        <div class="d-flex justify-content-end">
          <button class="btn btn-secondary" @click="resetForm">ΑΚΥΡΩΣΗ</button>
          <button class="btn btn-primary ms-2 save-button" @click="openSaveModal">ΑΠΟΘΗΚΕΥΣΗ</button>
        </div>
      </div>
    </div>

    <!-- Display list of rooms -->
    <div class="room-list mt-2 ms-5">
      <div v-for="(room, index) in roomTypes" :key="room.id" class="room-item">
        <div class="card shadow-custom p-3 mb-3 bg-white rounded position-relative">
          <!-- Non-Editable View -->
          <div v-if="!room.isEditing">
            <div class="row" style="color:#A6A6A6; font-weight:bolder">
              <!-- Left Column -->
              <div class="col-md-4">
                <p class="label-with-circle"><strong>ROOM TYPE</strong><hr/></p>
                <p>{{ room.title }}</p>
              </div>
              <!-- Middle Column -->
              <div class="col-md-4">
                <p class="label-with-circle"><strong>ΜΕΓΕΘΟΣ ΔΩΜΑΤΙΟΥ</strong><hr/></p>
                <p>{{ room.size }} τ.μ.</p>
                <p class="label-with-circle"><strong>ALLOTMENT</strong><hr/></p>
                <p>{{ room.allotment }}</p>
              </div>
              <!-- Right Column -->
              <div class="col-md-4">
                <p class="label-with-circle"><strong>CAPACITY</strong><hr/></p>
                <div>
                  <p><strong>Ελάχιστη:</strong> <span>{{ room.min_capacity }} {{ room.min_capacity === 1 ? 'άτομο' : 'άτομα' }}</span></p>
                  <p><strong>Μέγιστη:</strong> <span>{{ room.max_capacity }} {{ room.max_capacity === 1 ? 'άτομο' : 'άτομα' }}</span></p>
                </div>
              </div>
            </div>

            <!-- Edit Button -->
            <div class="action-buttons">
              <button class="btn border-0 p-1 edit" @click="editRoom(index)">
                <img src="../assets/images/edit-budget.png" alt="Edit" width="30">
              </button>
            </div>
          </div>

          <!-- Editable View -->
          <div v-else>
            <div class="row" style="color:#4A4A4A; font-weight:bolder">
              <!-- Left Column -->
              <div class="col-md-4">
                <p class="label-with-circle2"><strong>ROOM TYPE</strong><hr/></p>
                <input
                  type="text"
                  v-model="room.title"
                  class="form-control"
                  maxlength="255"
                  required
                />
              </div>
              <!-- Middle Column -->
              <div class="col-md-4 form-group">
                <p class="label-with-circle2"><strong>ΜΕΓΕΘΟΣ ΔΩΜΑΤΙΟΥ</strong><hr/></p>
                <input
                  type="number"
                  v-model.number="room.size"
                  class="form-control"
                /><br/>
                <p class="label-with-circle2"><strong>ALLOTMENT</strong><hr/></p>
                <input
                  type="number"
                  v-model.number="room.allotment"
                  class="form-control"
                />
              </div>
              <!-- Right Column -->
              <div class="col-md-4 form-group">
                <p class="label-with-circle2"><strong>CAPACITY</strong><hr/></p>
                <div>
                  <label><strong>Ελάχιστη:</strong></label>
                  <input
                    type="number"
                    v-model.number="room.min_capacity"
                    class="form-control"
                    placeholder="Ελάχιστη"
                  />
                  <label><strong>Μέγιστη:</strong></label>
                  <input
                    type="number"
                    v-model.number="room.max_capacity"
                    class="form-control"
                    placeholder="Μέγιστη"
                  /><br><br><br><br><br><br>
                </div>
              </div>
            </div>

            <div class="row form-actions mt-4">
              <div class="d-flex justify-content-end">
                <button class="btn btn-secondary justify-content-end align-items-end" @click="cancelEdit(index)">ΑΚΥΡΩΣΗ</button>
              </div>
            </div>

            <!-- Form Actions -->
            <div class="action-buttons mt-5">
              <button class="btn border-0 p-1 mb-2 save mt-3" @click="openEditModal(index)">
                <img src="../assets/images/save.png" alt="Save" width="30">
              </button>
              <button class="mt-5 btn border-0 p-1 delete" @click="openDeleteModal(index)">
                <img src="../assets/images/bin.png" alt="Delete" width="30">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Save Confirmation Modal -->
    <div class="modal fade" id="saveModal" tabindex="-1" aria-labelledby="saveModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="saveModalLabel">Επιβεβαίωση Αποθήκευσης</h5>
            <button type="button" class="close" @click="closeSaveModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Είστε σίγουροι ότι θέλετε να αποθηκεύσετε το δωμάτιο;
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeSaveModal">Ακύρωση</button>
            <button type="button" class="btn btn-primary" @click="confirmSaveRoom">Αποθήκευση</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Confirmation Modal -->
    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editModalLabel">Επιβεβαίωση Επεξεργασίας</h5>
            <button type="button" class="close" @click="closeEditModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Είστε σίγουροι ότι θέλετε να αποθηκεύσετε τις αλλαγές;
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeEditModal">Ακύρωση</button>
            <button type="button" class="btn btn-primary" @click="confirmSaveRoomEdit">Αποθήκευση</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">Επιβεβαίωση Διαγραφής</h5>
            <button type="button" class="close" @click="closeDeleteModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Είστε σίγουροι ότι θέλετε να διαγράψετε αυτόν τον τύπο δωματίου;
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeDeleteModal">Ακύρωση</button>
            <button type="button" class="btn btn-danger" @click="confirmDeleteRoom">Διαγραφή</button>
          </div>
        </div>
      </div>
    </div>
   


    <div class="modal fade" id="deleteSeasonModal" tabindex="-1" aria-labelledby="deleteSeasonModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteSeasonModalLabel">Επιβεβαίωση Διαγραφής</h5>
            <button type="button" class="close" @click="closeDeleteSeasonModal">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή τη σεζόν;
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeDeleteSeasonModal">Ακύρωση</button>
            <button type="button" class="btn btn-danger" @click="confirmDeleteSeason">Διαγραφή</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
  import { useHead } from '@vueuse/head';
import { useToast } from 'vue-toastification';
import { createRoomType, getRoomTypes, updateRoomType, deleteRoomType } from '@/services/room-types';
import { getBudget, updateBudget, updateSupplement, updateSpa } from "@/services/budget";
import { getSeason, createSeason, deleteSeason } from '@/services/seasons';
import Select2 from "vue3-select2-component";


export default {
  components: {
    Select2,
  },
  data() {
    return {
      seasons: [], 
      totalSeason: 0,
      totalSeasonHigh: 0,
      totalSeasonLow: 0,
      totalSeasonMiddle: 0,
      showForm: false,
      select2Settings: { multiple: true },

      form: {
        title: '',
        size: null,
        min_capacity: null,
        max_capacity: null,
        allotment: null,
      },
      roomTypes: [],
      boardType: '', // Single value for board type
      
      supplements: [], // Current supplements array
      boardTypesOptions: [
        { id: 'BB', text: 'BB' },
        { id: 'HB', text: 'HB' },
        { id: 'FB', text: 'FB' },
        { id: 'AI', text: 'AI' },
      ],
      supplementsOptions: [
        { id: 'HB', text: 'HB' },
        { id: 'FB', text: 'FB' },
        { id: 'AI', text: 'AI' },
      ],
      editableBoardType: '',
      editableSupplements: [], // Editable version for multi-select
      isEditingBoardTypes: false,
      isEditingSupplements: false,

      hasSpa: '', 
      editableSpa: '', // Holds the editable value (1 or 0)
      editableSpaOperation: '', // Holds the editable value (1 or 0)
      isEditingSpa: false,

      currentDeleteIndex: null, 

    };
  },
  methods: {
    // Fetch room types
    async fetchRoomTypes() {
      try {
        const response = await getRoomTypes(this.$route.params.budget_id);
        this.roomTypes = response.data.map((room) => ({ ...room, isEditing: false }));
      } catch (error) {
        console.error('Error fetching room types:', error);
      }
    },

    async fetchSeasons() {
      try {
        const response = await getSeason(this.$route.params.budget_id);
        if (response.success) {
          this.seasons = response.data.map(season => ({
            ...season,
            isEditing: false
          }));
        }
      } catch (error) {
        console.error('Error fetching seasons:', error);
      }
    },

    addSeason() {
  // Get the last season's `season_date_to` value
  const lastSeason = this.seasons[this.seasons.length - 1];
  let nextSeasonStartDate = '';

  if (lastSeason && lastSeason.season_date_to) {
    // Calculate the next day after the last season's `season_date_to`
    const lastSeasonDateTo = new Date(lastSeason.season_date_to);
    nextSeasonStartDate = new Date(lastSeasonDateTo);
    nextSeasonStartDate.setDate(lastSeasonDateTo.getDate() + 1); // Add one day
    nextSeasonStartDate = nextSeasonStartDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  }

  // Add a new season with the calculated `season_date_from`
  this.seasons.push({
    season_date_from: nextSeasonStartDate || '', // Use the calculated date or leave empty
    season_date_to: '', // Keep empty as it's yet to be set
    season_type: 'Middle', // Default season type
    isEditing: true // Set as editable
  });
},


    editSeason(index) {
      this.seasons[index].isEditing = true;
    },
    async confirmDeleteSeason() {
  try {
    if (this.currentDeleteIndex !== null) {
      await this.deleteSeason(this.currentDeleteIndex); // Use the correct currentDeleteIndex
      this.saveAllSeasons(); // Save the remaining seasons
      $('#deleteSeasonModal').modal('hide');

    }
  } catch (error) {
    console.error('Error deleting room:', error);
  } finally {
    this.closeDeleteModal(); // Always close the modal
  }
},

async saveAllSeasons() {
      const response = await createSeason(this.$route.params.budget_id, { seasons: this.seasons });
      if (response.success) {
        this.fetchSeasons();
        this.calcSeasonDays();
        useToast().success(response.message);
      } else {
        useToast().error(response.message);
      }
    },

    cancelEditSeason(index) {
      this.seasons[index].isEditing = false;
      this.fetchSeasons();
    },

    async deleteSeason(index) {
    const season = this.seasons[index];
    console.log(season);
    if (season.id) {
      try {
        await deleteSeason(season.id); // Make API call to delete season by ID
        this.seasons.splice(index, 1);
      } catch (error) {
        console.error('Error deleting season:', error);
      }
    } else {
      this.seasons.splice(index, 1); // If no ID, just remove from local array
    }
  },
  async calcSeasonDays() {
      const response = await getBudget(this.$route.params.budget_id);
      if (response.data) {
        this.totalSeason = response.data.data.total_season;
        this.totalSeasonHigh = response.data.data.total_season_high;
        this.totalSeasonLow = response.data.data.total_season_low;
        this.totalSeasonMiddle = response.data.data.total_season_middle;
      }
    },

    showAddForm() {
      this.resetForm();
      this.showForm = true;
    },

    resetForm() {
      this.form = {
        title: '',
        size: null,
        min_capacity: null,
        max_capacity: null,
        allotment: null,
      };
      this.boardType = '';
      this.hasSpa = '';
      this.supplement = '';
      this.showForm = false;
    },

    openSaveModal() {
      $('#saveModal').modal('show');
    },
    closeSaveModal() {
      $('#saveModal').modal('hide');
    },

    async confirmSaveRoom() {
      try {
        const response = await createRoomType(this.$route.params.budget_id, { ...this.form, boardType: this.boardType, supplement: this.supplement });
        if (response.success) {
          useToast().success(response.message);
        } else {
          useToast().error(response.message);
        }
        this.roomTypes.push({ ...response.data, isEditing: false });
        this.resetForm();
        this.closeSaveModal();
        this.fetchRoomTypes();
      } catch (error) {
        console.error('Error saving room:', error);
      }
    },

    editRoom(index) {
      this.roomTypes[index].isEditing = true;
    },

    openEditModal(index) {
      $('#editModal').modal('show');
    },
    closeEditModal() {
      $('#editModal').modal('hide');
    },

    async fetchBudget() {
      try {
        const response = await getBudget(this.$route.params.budget_id);
        this.supplements = response.data.data.supplements || [];
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching budget data:', error);
      }
    },

    async confirmSaveRoomEdit() {
      try {
        const room = this.roomTypes.find((room) => room.isEditing);
        const response = await updateRoomType(room.id, { ...room, boardType: this.boardType, supplement: this.supplement });
        if (response.success) {
          useToast().success(response.message);
        } else {
          useToast().error(response.message);
        }
        room.isEditing = false;
        this.closeEditModal();
      } catch (error) {
        console.error('Error updating room:', error);
      }
    },

    cancelEdit(index) {
      this.roomTypes[index].isEditing = false;
    },

   

    openDeleteModal(index) {
      this.currentDeleteIndex = index;
      $('#deleteModal').modal('show');
    },

    openDeleteSeasonModal(index) {
      this.currentDeleteIndex = index;
      $('#deleteSeasonModal').modal('show');
    },
    closeDeleteModal() {
      $('#deleteModal').modal('hide');
    },

    closeDeleteSeasonModal() {
      $('#deleteSeasonModal').modal('hide');
    },

    async confirmDeleteRoom() {
      try {
        const room = this.roomTypes[this.currentDeleteIndex];
        const response = await deleteRoomType(room.id);
        if (response.success) {
          useToast().success(response.message);
        } else {
          useToast().error(response.message);
        }
        this.roomTypes.splice(this.currentDeleteIndex, 1);
        this.closeDeleteModal();
      } catch (error) {
        console.error('Error deleting room:', error);
      }
    },

    // Edit and save methods for board types
    editBoardTypes() {
      this.editableBoardType = this.boardType; // Set to current value
      this.isEditingBoardTypes = true;
    },
   // Enter editing mode and pre-select the current value
   editSpa() {
      this.editableSpa = this.hasSpa === "NAI" ? "1" : "0";
      this.editableSpaOperation = this.spa_operation_id;
      this.isEditingSpa = true;
    },
    // Cancel editing mode
    cancelEditSpa() {
      this.isEditingSpa = false;
    },
    // Save the updated SPA value
    async saveSpa() {
      try {
        await updateSpa(this.$route.params.budget_id, { hasSpa: this.editableSpa, spa_operation_id: this.editableSpaOperation });
        this.hasSpa = this.editableSpa === "1" ? "NAI" : "ΟΧΙ"; // Update display value
        this.spa_operation_id = this.editableSpaOperation;
        this.isEditingSpa = false; // Exit editing mode
        useToast().success("Επιτυχής καταχώρηση!!!");
      } catch (error) {
        console.error("Error updating SPA:", error);
        useToast().error("Failed to update Spa value.");
      }
    },
    saveBoardTypes() {
      this.boardType = this.editableBoardType; // Store as a single value
      updateBudget(this.$route.params.budget_id, {supplement: this.supplement, basic_board: this.editableBoardType});
      this.isEditingBoardTypes = false;
      useToast().success('Eπιτυχής καταχώρηση!!!');
    },
    cancelEditBoardTypes() {
      this.isEditingBoardTypes = false;
    },

    async editSupplements() {
      try {
        // Fetch budget data and populate current supplements
        const response = await getBudget(this.$route.params.budget_id);
        this.supplements = response.data.data.supplements;

        // Initialize editable supplements
        this.editableSupplements = [...this.supplements];

        this.isEditingSupplements = true;
      } catch (error) {
        console.error("Error fetching budget data:", error);
      }
    },
    saveSupplements() {
      // Update the supplements array and send to the API
      this.supplements = [...this.editableSupplements];

      updateSupplement(this.$route.params.budget_id, { supplements: this.supplements })
        .then(() => {
          useToast().success("Eπιτυχής καταχώρηση!!!");
        })
        .catch((error) => {
          console.error("Error updating supplements:", error);
        });

      this.isEditingSupplements = false;
    },
    cancelEditSupplements() {
      this.isEditingSupplements = false;
    },

    // Function to populate both basic board and supplement
    async populateBoardAndSupplement() {
      const r = await getBudget(this.$route.params.budget_id);
      if (r.data.data.basic_board) {
        this.boardType = r.data.data.basic_board;
        this.hasSpa = r.data.data.hasSpa == 1 ? "NAI" : "ΟΧΙ"; // Converts 1 to "NAI" and 0 to "ΟΧΙ"
        this.spa_operation_id = r.data.data.spa_operation_id; // Converts 1 to "NAI" and 0 to "ΟΧΙ"
        this.spa_operation = r.data.data.spa_operation; // Converts 1 to "NAI" and 0 to "ΟΧΙ"
      }
      if (r.data.data.supplement) {
        this.supplement = r.data.data.supplement;
      }
    }
  },
  mounted() {
    useHead({
        title: 'Τύποι Δωματίων',
      });
      this.fetchSeasons();
    this.fetchRoomTypes();
    this.calcSeasonDays();
      this.populateBoardAndSupplement();
      this.fetchBudget();
  },
};
</script>

<style scoped>
.form-container {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.card {
  padding: 15px;
  background: #fff;
  border-radius: 30px;
  border: 1px solid #ddd;
  position: relative;
}

.action-buttons {
  position: absolute;
  top: 100px;
  right: 10px;
}

hr {
  margin: 10px 0;
}

.modal-backdrop {
  opacity: 0.5 !important;
}


/* Aligning middle elements like Basic Board and Supplement */
.align-middle {
  margin-top: 30px; /* Adjust this value based on the spacing you want */
}

/* Custom shadow for room-card */
.shadow-custom {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.shadow-custom:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
}

.edit{
    position: absolute;
  
    top: 25%; /* Adjust the vertical position to be slightly above middle */
    right: -32px;
    transform: translateY(-160%); /* Center vertically relative to its position */
  
  }
  
  .save {
    position: absolute;
    top: 10%; /* Adjust as needed to lower the button */
    right: -32px;
    transform: translateY(-115%); /* Further adjust for desired position */
  }
  
  .delete {
    position: absolute;
    top: 30%; /* Adjust to position higher or lower */
    right: -32px;
    transform: translateY(-90%); /* Further adjust for desired position */
  
  }

.custom-action-buttons {
  position: absolute;
  top: 40%;
  right: -32px;
  transform: translateY(-172%);
  background: #67B662;
  width: 40px;
  height: 40px;
}

.custom-action-buttons:hover {
  position: absolute;
  top: 40%;
  right: -32px;
  transform: translateY(-172%);
  background: #67B662;
  width: 40px;
  height: 40px;
}

.seasonality-pricing-container {
  padding: 20px;
}


.delete-button-seasons img {
  width: 30px;
  height: 30px;
}



.delete-button-seasons {
  position: absolute;
  background-color: #D03535;
  border-radius: 8px;
  top: 42px;
  right: -32px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.delete-button-seasons,
.delete-button-seasons:hover {
  background-color: #D03535;
}
.delete-button-seasons {
    position: absolute;
    top: 72%; /* Adjust to position higher or lower */
    right: -32px;
    transform: translateY(30%); /* Further adjust for desired position */
  
  }

  .edit-button-seasons img {
  width: 30px;
  height: 30px;
}

.edit-button-seasons  {
  position: absolute;
  background-color: #67B662 !important;
  border-radius: 8px;
  top: 2px;
  right: -32px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transform: translateY(-160%); /* Further adjust for desired position */

}

.save-seasons {
    position: absolute;
    top: 40%; /* Adjust as needed to lower the button */
    right: -32px;
    transform: translateY(-90%); /* Further adjust for desired position */
  }
  
</style>
