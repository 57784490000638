<template>
  <div class="budgets">
    <!-- Add New Budget Button (only shown when not in form view) -->
    <div class="new-budget-button d-flex justify-content-end" v-if="!isEditing && !isAddingNewBudget">
      <button class="btn-add-new" @click="showNewBudgetForm">
        ΠΡΟΣΘΗΚΗ ΝΕΟΥ
        <span class="btn-add-new-icon">
          <i class="icofont-plus"></i>
        </span>
      </button>
    </div>

    <!-- Edit/Add Budget Form (replaces the current view) -->
    <div v-if="isAddingNewBudget || isEditing" class="new-budget-form">
      <h3>{{ isEditing ? 'Επεξεργασία Προϋπολογισμού' : 'Νέος Προϋπολογισμός' }}</h3>
      <div class="form-group">
        <label for="title">Τίτλος:</label>
        <input type="text" v-model="newBudget.title" id="title" placeholder="Εισαγωγή τίτλου" />
      </div>
      <div class="form-group">
        <label for="year">Έτος:</label>
        <input type="number" v-model="newBudget.year" id="year" placeholder="Εισαγωγή έτους" />
      </div>

      <!-- New Select Field for Budget List (only shown in create form) -->
      <div v-if="!isEditing" class="form-group">
        <label for="budget_id">Δημιουργία σαν αντιγραφή του προϋπολογισμού:</label>
        <select v-model="newBudget.budget_id" id="budget_id">
          <option value="">Επιλέξτε Προϋπολογισμό</option>
          <option v-for="budget in budgets" :key="budget.id" :value="budget.id">
            {{ budget.year }} - {{ budget.title }}
          </option>
        </select>
      </div>


      <div class="row form-actions mt-4">
        <div class="d-flex justify-content-end">
          <button class="btn  btn-secondary" :disabled="isSaving" @click="cancelNewBudget">ΑΚΥΡΩΣΗ</button>
          <button class="btn  btn-primary ms-2 save-button" :disabled="isSaving" @click="saveNewBudget">ΑΠΟΘΗΚΕΥΣΗ</button>
        </div>
      </div>
    </div>

    <!-- Render Budgets grouped by year in descending order, and budgets sorted by ID DESC -->
    <div v-if="!isAddingNewBudget && !isEditing && sortedGroupedBudgets.length > 0">
      <div v-for="({ year, budgets }) in sortedGroupedBudgets" :key="year" class="accordion-item">
        <div class="accordion-header" @click="toggleAccordion(year)">
          <h3 class="fw-bolder">ΠΡΟΫΠΟΛΟΓΙΣΜΟΙ {{ year }}</h3>
          <i class="icofont-rounded-down accordion-icon" :class="accordionOpen[year] ? 'rotate' : ''"></i>
        </div>
        <div class="accordion-content" v-show="accordionOpen[year]">
          <div class="budget-item budget-row" v-for="budget in budgets" :key="budget.id" @click="navigateToBudget($event, budget)">
            <div class="fw-bolder budget-title">{{ budget.title }} ({{ budget.year }})</div>
            <div class="budget-actions">
              <!-- Lock/Unlock Button -->
                <img class="accordion-icon" :src="getLockImage(budget.isFinal)" alt="Edit" width="50" :style="getImageStyles(budget.isFinal)" @click.stop="showLockModal(budget)">

                <img @click.stop="editBudget(budget)" class="accordion-icon" src="../assets/images/edit-budget.png" alt="Edit" width="50" style="background-color: #67B662; color:#F1F8F1">
 
                <img @click.stop="copyBudget(budget)" class="accordion-icon" src="../assets/images/duplicate.png" alt="Edit" width="50" style="background-color: #437AA1; color:#F1F8F1">

              <img @click.stop="showDeleteModal(budget.id)" class="accordion-icon" src="../assets/images/bin.png" alt="Edit" width="50" style="background-color: #D98E1B; color:#F1F8F1">

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">Confirm Delete</h5>
            <button type="button" class="close" @click="closeModal('deleteModal')" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Are you sure you want to delete this budget?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal('deleteModal')">Cancel</button>
            <button type="button" class="btn btn-danger" @click="confirmDelete">Delete</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Lock/Unlock Confirmation Modal -->
    <div class="modal fade" id="lockModal" tabindex="-1" role="dialog" aria-labelledby="lockModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="lockModalLabel">Confirm Lock/Unlock</h5>
            <button type="button" class="close" @click="closeModal('lockModal')" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Are you sure you want to {{ selectedBudget && selectedBudget.isFinal === 1 ? 'unlock' : 'lock' }} this budget?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal('lockModal')">Cancel</button>
            <button type="button" class="btn btn-primary" @click="confirmLock">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head';
import { getAllBudgets, createBudget, markBudgetAsFinal, deleteBudget, updateBudgetForm } from '@/services/budget';
import { useToast } from 'vue-toastification';

export default {
  name: 'Budgets',
  data() {
    return {
      budgets: [],
      sortedGroupedBudgets: [],
      accordionOpen: {},
      isAddingNewBudget: false,
      isEditing: false,
      newBudget: {
        id: null,
        title: '',
        year: '',
        budget_id: ''
      },
      budgetToDelete: null,
      selectedBudget: null,
      isSaving: false // Track the saving process
    };
  },
  async mounted() {
    await this.fetchBudgets();

    useHead({
      title: 'Budgets',
      meta: [
        { name: 'description', content: 'Budgets' },
        { property: 'og:title', content: 'Budgets' },
        { property: 'og:description', content: 'Budgets' }
      ]
    });
  },
  methods: {
    getLockImage(isFinal) {
      return isFinal === 1 
        ? require('@/assets/images/lock-open.png') 
        : require('@/assets/images/lock-close.png');
    },
    getLockImage(isFinal) {
      return isFinal === 1 
        ? require('@/assets/images/lock-open.png') 
        : require('@/assets/images/lock-close.png');
    },
    getImageStyles(isFinal) {
      return isFinal === 1 
        ? { backgroundColor: '#963636', color: '#FFFFFF' }
        : { backgroundColor: '#AAAAAA', color: '#FFFFFF' };
    },
    async fetchBudgets() {
      const response = await getAllBudgets(this.$route.params.hotel_id);
      if (response.data.success) {
        this.budgets = response.data.data;
        this.groupAndSortBudgets();
      }
    },
    navigateToBudget(event, budget) {
      this.$router.push('/hotels/' + this.$route.params.hotel_id + '/budgets/' + budget.id + '/domika/room-type');
    },
    toggleAccordion(year) {
      this.accordionOpen[year] = !this.accordionOpen[year];
    },
    showNewBudgetForm() {
      this.isAddingNewBudget = true;
      this.isEditing = false;
    },
    cancelNewBudget() {
      this.isAddingNewBudget = false;
      this.isEditing = false;
      this.newBudget = { id: null, title: '', year: '', budget_id: '' };
    },
    async editBudget(budget) {
      this.newBudget = { ...budget };
      this.isEditing = true;
      this.isAddingNewBudget = false;
    },
    async copyBudget(budget) {
      this.newBudget = {
        title: `${budget.title} (Copy)`,
        year: budget.year,
        budget_id: budget.id
      };
      this.isAddingNewBudget = true;
      this.isEditing = false;
    },
    async saveNewBudget() {
      this.isSaving = true; // Start saving process
      if (this.isEditing) {
        try {
          const response = await updateBudgetForm(this.newBudget.id, {
            title: this.newBudget.title,
            year: this.newBudget.year
          });

          if (response.data.success) {
            useToast().success(response.data.message);
            const updatedBudget = response.data.data;

            const index = this.budgets.findIndex(b => b.id === updatedBudget.id);
            if (index !== -1) {
              this.budgets[index] = updatedBudget;
            }

            this.groupAndSortBudgets();
            this.cancelNewBudget();
          } else {
            useToast().error(response.data.message);
          }
        } catch (error) {
          useToast().error('Error during update.');
        } finally {
          this.isSaving = false; // End saving process
        }
      } else {
        try {
          const response = await createBudget(this.$route.params.hotel_id, {
            title: this.newBudget.title,
            year: this.newBudget.year,
            budget_id: this.newBudget.budget_id
          });

          if (response.data.success) {
            useToast().success(response.data.message);
            this.budgets.push(response.data.data);
            this.groupAndSortBudgets();
            this.cancelNewBudget();
          } else {
            useToast().error(response.data.message);
          }
        } catch (error) {
          useToast().error('Error saving budget.');
        } finally {
          this.isSaving = false; // End saving process
        }
      }
    },
    showDeleteModal(budgetId) {
      this.budgetToDelete = budgetId;
      $('#deleteModal').modal('show');
    },
    async confirmDelete() {
      try {
        await deleteBudget(this.budgetToDelete);
        useToast().success('Budget deleted successfully.');
        this.fetchBudgets();
        this.closeModal('deleteModal');
      } catch (error) {
        useToast().error('Error deleting budget.');
      }
    },
    showLockModal(budget) {
      this.selectedBudget = budget;
      $('#lockModal').modal('show');
    },
    async confirmLock() {
      try {
        await markBudgetAsFinal(this.selectedBudget.id);
        useToast().success('Budget lock/unlock status updated.');
        this.fetchBudgets();
        this.closeModal('lockModal');
      } catch (error) {
        useToast().error('Error updating lock/unlock status.');
      }
    },
    closeModal(modalId) {
      // Programmatically hide the Bootstrap modal using jQuery
      $(`#${modalId}`).modal('hide');
    },
    groupAndSortBudgets() {
      const grouped = this.budgets.reduce((acc, budget) => {
        const year = budget.year;
        if (!acc[year]) {
          acc[year] = [];
        }
        acc[year].push(budget);
        return acc;
      }, {});

      this.sortedGroupedBudgets = Object.entries(grouped)
        .map(([year, budgets]) => ({
          year: parseInt(year),
          budgets: budgets.sort((a, b) => b.id - a.id)
        }))
        .sort((a, b) => b.year - a.year);
    }
  }
};
</script>

<style scoped>
/* Same styles as before, with minor changes for the disabled state of buttons */
.budgets {
  max-width: 90%;
  margin: 0 auto;
}

.new-budget-button {
  text-align: right;
  margin-bottom: 10px;
}

.btn-add-new {
  background-color: white;
  color: #333;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-add-new:hover {
  border-color: #ccc;
}

.btn-add-new-icon {
  background-color: #f1a417;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.action-buttons {
  position: absolute;
  top: 100px;
  right: 10px;
}


.new-budget-form {
  background-color: #eeeeee;
  padding: 15px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input, .form-group select {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 14px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn.cancel {
  background-color: #f44336;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn.save {
  background-color: #4caf50;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn.save:disabled, .btn.cancel:disabled {
  background-color: #9e9e9e;
  cursor: not-allowed;
}

.accordion-item {
  margin-bottom: 5px;
}

.accordion-header {
  background-color: #E1E1E1;
  padding: 10px;
  font-weight: bolder;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #4C4C4C;
  width:100%;
}

.accordion-icon {
  transition: transform 0.3s ease;
  font-size: 16px;
  font-weight:bolder;
  color: #ACACAC;
  border-radius: 10px;
}

.accordion-icon.rotate {
  transform: rotate(180deg);
}

.accordion-content {
  border-radius: 5px;
  margin-top: 10px;
}

.budget-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.budget-item:last-child {
  border-bottom: none;
}

.budget-title {
  font-size: x-large;
  color: #333;
}

.budget-actions {
  display: flex;
  gap: 10px;
}

.btn {
  padding: 8px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn.lock {
  background-color: #f1f1f1;
}

.btn.lock.locked {
  color: red;
}

.btn.lock.unlocked {
  color: gray;
}

.btn.edit {
  background-color: #4caf50;
  color: white;
}

.btn.copy {
  background-color: #2196f3;
  color: white;
}

.btn.delete {
  background-color: #ff9800;
  color: white;
}

.icofont {
  font-size: 18px;
}
</style>
